import {
  ArBrandModals,
  ArForm,
  ArInputType,
  ArModal,
  inputType,
  nodeType,
  varientType,
} from "@anarock/ar-common-react";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { useForm } from "antd/es/form/Form";
import { useGetStaticEnumsQuery } from "app/services/enums";
import { useCreateLandLordMutation, useUpdateLandLordMutation } from "app/services/wip";
import { convertEnumsToRadioOptions, findEnumKeyByValue, isNotNullorUndefined } from "app/utils/helperFunctions";
import { useQueryApiError } from "app/utils/useQueryApiError";
import { requiredRulesOnChange } from "components/requirmentFormUi/rules";
import { useToastService } from "components/toastService";
import { GET_API_ERRORS } from "constants/getAPIErrors";
import { FORM_FIELDS, FORM_MODAL_STRING } from "constants/RequirementFrom";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { TOAST_MESSAGES } from "constants/ToastMessages";
import { ModalFormEditFlowInfoBar, RequiredTextNode } from "features/invoices/components/formHelpers";
import React, { useEffect, useState } from "react";

import { AddLLModalProps } from "./index.interface";
import styles from "./index.module.scss";

const AddLLModal: React.FC<AddLLModalProps> = ({ onSubmit, sendDataAndCloseModal, editLLData, mallId, ...props }) => {
  const [form] = useForm();

  const editFlow = isNotNullorUndefined(editLLData?._id);
  const { data: enums, isError, isLoading, isFetching } = useGetStaticEnumsQuery();
  useQueryApiError(isError, isLoading, isFetching, enums, GET_API_ERRORS.UNABLE_TO_FETCH_ENUMS);

  const [createLL, { isLoading: createLoading }] = useCreateLandLordMutation();
  const [updateLL, { isLoading: updateLLLoading }] = useUpdateLandLordMutation();
  const [openIndustryModal, setIndustryModal] = useState<boolean>(false);
  const [industryValues, setIndustryValues] = useState<CheckboxValueType>();

  const handleSubmitIndustry = (value: CheckboxValueType) => {
    setIndustryModal(!openIndustryModal);
    setIndustryValues(value);
  };
  const handleClickIndustry = () => {
    setIndustryModal(!openIndustryModal);
  };

  const IndustryModal = (
    <ArBrandModals.ArRadioOptionsModal
      destroyOnClose={true}
      width={"500px"}
      open={openIndustryModal}
      onCancel={() => handleClickIndustry()}
      title={FORM_MODAL_STRING.SELECT_INDUSTRY}
      submitText={FORM_MODAL_STRING.DONE}
      onSubmit={(value) => handleSubmitIndustry(value)}
      showSearch={true}
      options={Object.entries(enums?.data?.industry || {}).map(([key, value]) => {
        return { label: value, value: key };
      })}
      placeholder={FORM_MODAL_STRING.SEARCH_INDUSTRY}
    />
  );

  const { errorToast, successToast } = useToastService();

  const AddBrandForm = {
    type: nodeType.array,
    childNode: [
      RequiredTextNode(FORM_FIELDS.LEGAL_NAME, FORM_FIELDS.LEGAL_NAME),
      {
        type: nodeType.input,
        elementData: {
          inputType: inputType.selectBox,
          formItemProps: {
            name: FORM_FIELDS.LL_TYPE,
            rules: requiredRulesOnChange,
            initialValue: undefined,
          },
          inputProps: {
            type: ArInputType.text,
            label: FORM_FIELDS.LL_TYPE_LABEL,
            required: true,
            options: convertEnumsToRadioOptions(enums?.data?.landlordType),
          },
        },
      },
      {
        type: nodeType.container,
        elementData: {
          body: IndustryModal,
        },
        childNode: {
          type: nodeType.input,
          elementData: {
            inputType: inputType.text,
            inputProps: {
              type: ArInputType.text,
              label: FORM_FIELDS.INDUSTRY_LABEL,
              varient: varientType.default,
              required: true,
              readOnly: true,
              onClick: handleClickIndustry,
            },
            formItemProps: {
              rules: requiredRulesOnChange,
              name: FORM_FIELDS.INDUSTRY,
            },
          },
        },
      },
    ],
  };
  const handleSubmitModal = async () => {
    try {
      await form.validateFields();

      let addLLResponse;
      try {
        addLLResponse = await createLL({
          type: form.getFieldValue(FORM_FIELDS.LL_TYPE),
          name: form.getFieldValue(FORM_FIELDS.LEGAL_NAME),
          legalEntityName: form.getFieldValue(FORM_FIELDS.LEGAL_NAME),
          industry: industryValues as string,
          mallId: mallId,
        }).unwrap();
      } catch {
        errorToast(STRING_CONSTANTS.SOMETHING_WENT_WRONG);
      }
      if (addLLResponse?.success) {
        sendDataAndCloseModal && sendDataAndCloseModal(addLLResponse?.data?.landlord);
        form.resetFields();
        successToast(STRING_CONSTANTS.LL_ADDED_SUCCESSFULLY);
      } else {
        errorToast(addLLResponse?.reason || STRING_CONSTANTS.SOMETHING_WENT_WRONG);
      }
    } catch {
      errorToast(TOAST_MESSAGES.ENTER_VALID_DATA);
    }
  };
  const handleUpdatetModal = async () => {
    try {
      await form.validateFields();

      let addLLResponse;
      try {
        addLLResponse = await updateLL({
          type: form.getFieldValue(FORM_FIELDS.LL_TYPE),
          name: form.getFieldValue(FORM_FIELDS.LEGAL_NAME),
          legalEntityName: form.getFieldValue(FORM_FIELDS.LEGAL_NAME),
          industry: industryValues as string,
          mallId: mallId,
          _id: editLLData?._id,
          clientId: editLLData?.clientId,
        }).unwrap();
      } catch {
        errorToast(STRING_CONSTANTS.SOMETHING_WENT_WRONG);
      }
      if (addLLResponse?.success) {
        sendDataAndCloseModal && sendDataAndCloseModal(addLLResponse?.data?.landlord);
        successToast(STRING_CONSTANTS.LL_UPDATE_SUCCESSFULLY);
      } else {
        errorToast(addLLResponse?.reason || STRING_CONSTANTS.SOMETHING_WENT_WRONG);
      }
    } catch {
      errorToast(TOAST_MESSAGES.ENTER_VALID_DATA);
    }
  };

  useEffect(() => {
    form.setFieldValue(FORM_FIELDS.INDUSTRY, enums?.data?.industry?.[industryValues as string]);
  }, [industryValues]);

  useEffect(() => {
    if (editLLData) {
      form.setFieldsValue({
        [FORM_FIELDS.LEGAL_NAME]: editLLData?.name,
        [FORM_FIELDS.LL_TYPE]: editLLData?.type,
      });
      setIndustryValues(findEnumKeyByValue(enums?.data?.industry || {}, editLLData?.industry || ""));
    }
  }, [editLLData, form]);

  return (
    <ArModal
      onSubmit={editFlow ? handleUpdatetModal : handleSubmitModal}
      width={"500px"}
      title={editFlow ? STRING_CONSTANTS.EDIT_LL : STRING_CONSTANTS.ADD_N_LL}
      destroyOnClose
      submitText={editFlow ? STRING_CONSTANTS.EDIT_DETAILS : STRING_CONSTANTS.Add_LL}
      confirmLoading={createLoading || updateLLLoading}
      className={styles.selectBoxWidth}
      {...props}
    >
      {editFlow ? <ModalFormEditFlowInfoBar /> : null}
      <ArForm form={form} formNode={AddBrandForm} />
    </ArModal>
  );
};

export default AddLLModal;
