export enum OPTION_LIST_SHOP_STATUS {
  NEW = "New",
  IN_NEGOTIATION = "In Negotiation",
  VISIT_DONE = "Visit Done",
  SHORTLISTED = "Shortlisted",
  SHARED = "Shared",
  REJECTED = "Rejected",
  DRAFT_WIP_CREATED = "Draft WIP Created",
  BILLED = "Billed",
  DROPPED = "Dropped",
  WIP_CREATED = "WIP Created",
}

export enum UNITS {
  SQFT = "sq. ft",
  FT = "ft",
  RUPEES = "Rs",
  PER_MONTH = "/m",
  SYMBOL_RUPEES = "₹",
  MONTHS = "months",
  YEARS = "years",
  DAYS = "Days",
  PERCENT = "%",
}

export enum SHOP_STATUS {
  NEW = "New",
  UPDATED = "Updated",
  EXPIRED = "Expired",
}

export enum BROCHURE_STATUS {
  PROCESSING = "PROCESSING",
  PROCESSED = "PROCESSED",
  FAILED = "FAILED",
}

export enum FILTER_TYPES {
  SINGLE_SELECT = "SINGLE_SELECT",
  MULTI_SELECT = "MULTI_SELECT",
  NUMERIC_RANGE = "NUMERIC_RANGE",
  DATE_RANGE = "DATE_RANGE",
  NESTED = "NESTED",
  TAB = "TAB",
}

export enum DATE_KEYS {
  FROM_DATE = "fromDate",
  TO_DATE = "toDate",
  DATE_FORMAT_API = "YYYY-MM-DD",
  DATE_FORMAT_UI = "DD-MM-YYYY",
}

export enum DOCUMNET_FILE_TYPE {
  PNG = "image/png",
  JPEG = "image/jpeg",
  PDF = "application/pdf",
}
export enum FEE_LETTER_TYPE {
  // SYSTEM = "System-generated fee letter",
  CLIENT_OWN = "Client's own fee letter",
  CUSTOM = "Customised fee letter",
}

export enum FEE_BASIS {
  FIXED_RENT = "Fixed rent",
  STAGGERED_RENT = "Staggered rent",
  PURE_REVENUE_SHARING = "Pure revenue sharing",
}

export enum FEE_LETTER_DOC_OPTIONS {
  PDF = "PDF",
  WORD = "WORD",
}
