import { ADD_BRAND_URLS } from "constants/ApiUrls";

import { api, GenericResponse } from "./api";

export interface IIndustryEnums {
  [key: string]: string;
}

export interface IAddBrand {
  brandName: string;
  legalEntityName: string;
  industry: string;
  brandLogoUrl: string;
  type: string;
  _id?: string;
}

export type IIndustryResponse = GenericResponse<{
  industryEnum: IIndustryEnums;
}>;

export type IGetSignedUrlResponse = GenericResponse<{
  brandLogoUploadUrlObj: {
    url: string;
    fields: {
      [key: string]: string;
    };
  };
}>;

export interface IUploadImageToUrl {
  url: string;
  body: FormData;
}

export const addBrandApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getIndustryEnums: builder.query<IIndustryResponse, void>({
      query: () => ({
        url: ADD_BRAND_URLS.INDUSTRY_ENUMS,
      }),
    }),
    getSignedUrl: builder.query<IGetSignedUrlResponse, string>({
      query: (brandName) => ({
        url: ADD_BRAND_URLS.GET_SIGNED_URL,
        params: {
          brandName,
        },
      }),
    }),
    uploadImageToUrl: builder.mutation<void, IUploadImageToUrl>({
      query: ({ url, body }) => ({
        url,
        method: "POST",
        body,
        credentials: "omit",
      }),
      transformResponse: (_, meta: any) => {
        const res = meta?.response?.headers.get("location");
        return res;
      },
    }),
    addBrand: builder.mutation<GenericResponse, IAddBrand>({
      query: (body) => ({
        url: ADD_BRAND_URLS.ADD_BRAND,
        body,
        method: "POST",
      }),
      invalidatesTags: ["Brands"],
    }),
    editBrand: builder.mutation<GenericResponse, IAddBrand>({
      query: (body) => ({
        url: ADD_BRAND_URLS.ADD_BRAND + "/" + body?._id,
        body,
        method: "PUT",
      }),
      invalidatesTags: ["Brands"],
    }),
  }),
});

export const {
  useGetIndustryEnumsQuery,
  useLazyGetSignedUrlQuery,
  useUploadImageToUrlMutation,
  useAddBrandMutation,
  useEditBrandMutation,
} = addBrandApi;
