import { ArBrandModals, ArButton, ButtonTypes } from "@anarock/ar-common-react";
import { ArRadioOptionsModalProps } from "@anarock/ar-common-react/dist/ui-components/ar-brand-modals/index.interface";
import { STRING_CONSTANTS } from "constants/StringConstants";
import React, { useEffect, useState } from "react";

import { AddBrandModal } from "./partials";

const BrandListModal: React.FC<ArRadioOptionsModalProps> = (props) => {
  const [brandModalOpen, setBrandModalOpen] = useState(false);
  const [open, setOpen] = useState(props.open || false);
  useEffect(() => {
    setOpen(props.open || false);
    setBrandModalOpen(false);
  }, [props.open]);
  const toggelAddBrandModal = () => {
    setBrandModalOpen(!brandModalOpen);
    setOpen(!open);
  };

  const showAddBtn = props?.options?.length === 0;
  const footerObj = showAddBtn
    ? { footer: false }
    : {
        footerButtons: [
          <ArButton key="1" type={ButtonTypes.Secondary} size="large" onClick={toggelAddBrandModal}>
            {STRING_CONSTANTS.ADD_BRAND}
          </ArButton>,
        ],
      };
  return (
    <React.Fragment>
      <ArBrandModals.ArRadioOptionsModal
        addDataButtonProps={
          showAddBtn
            ? {
                onClick: toggelAddBrandModal,
                children: STRING_CONSTANTS.ADD_BRAND,
              }
            : undefined
        }
        {...footerObj}
        {...props}
        open={open}
      />
      <AddBrandModal title={STRING_CONSTANTS.ADD_BRAND} open={brandModalOpen} onCancel={toggelAddBrandModal} />
    </React.Fragment>
  );
};

export default BrandListModal;
