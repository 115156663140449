import { IDocumentsUrL, IWip, IWIPApproval, IWIPProperty, IWipStatus } from "app/utils/interfaces/index.interface";
import { WIP_URLS } from "constants/ApiUrls";
import { IShopPocDetails } from "constants/ShopStructure";

import { IAnalyticsTable } from "./analytics";
import { api, GenericResponse, paginationParams } from "./api";
import { IComment } from "./collections";
import { INewLandLord } from "./createRequirmentApi";
import { IClientPOC, IUploadUrl } from "./invoices";
import { IPaginationParam } from "./notificationsApi";

export interface ICreateWipBody {
  brandRequirementDetailId: string;
  shopId: string;
}

export interface IIndependentWIPBody {
  requirement: {
    type: string;
    brandId: string;
    cityId: string;
  };
  profitCenter?: string;
  property: {
    shop?: {
      shopNumber: string;
      address: string;
      cityId: string;
      localityId: string;
      pincode: string;
    };
    //for requirement.type == Mall
    mallId?: string;
  };
}
export interface IBMUser {
  userId: string;
  userName: string;
  employeeId?: string;
  profitCenters?: string[];
}

export interface IWIPCommisionDetails extends IBMUser {
  type: string;
  weightage?: string;
  profitCenter?: string;
}

export interface IBillingMilestone {
  value: string;
  dueDate: string;
  key: string;
  weightage: string;
}
export interface IWIPLandlord {
  landlordId?: string;
  pocIds?: string[];
  landlordInfo?: INewLandLord;
  poc?: IClientPOC[];
}
export interface IWipBody {
  _id?: string;
  commercialDetails?: {
    area: IWipValue;
    rate: IWipValue;
    rent: IWipValue;
  };
  brokerageDetails?: IBrokerage[];
  draft?: boolean;
  billingMilestones?: IBillingMilestone[];
  shopRevenueSecured?: boolean;
  brandRevenueSecured?: boolean;
  commissions?: IWIPCommisionDetails[];
  approval?: IWIPApproval;
  landlord?: IWIPLandlord[];
  brand?: {
    pocIds: string[];
    brandId?: string;
  };
  projectId?: string;
  property?: IWIPProperty;
}
export interface IWipValue {
  value?: string | number;
  unit: string;
}
export interface IWipBrokerageValue {
  current: number;
  target?: number;
  okay?: number;
  query?: number;
  tentative?: number;
  flash?: number;
}

export interface IMonthlyBrokerage {
  month: string;
  shopBrokerage: IWipBrokerageValue;
  brandBrokerage: IWipBrokerageValue;
  monthKey?: string;
}
export interface IBrokerage {
  fy: string;
  shopBrokerage?: IWipBrokerageValue;
  brandBrokerage?: IWipBrokerageValue;
  monthlyBrokerageDetails?: IMonthlyBrokerage[];
}
export interface IDocumentUrl {
  url: string;
  alt: string;
  documentSideTag: string;
}
export interface IDocument {
  documentStructureId: string;
  documentURLs: IDocumentUrl[];
}

export interface IDocumentBody {
  projectId?: string;
  body: IDocument[];
}

export interface IDeleteDocumentBody {
  projectId?: string;
  body: {
    documentStructureId: string;
    wipStatusId: string;
  };
}

export interface IGetWipDynamicStatusBody {
  projectId?: string;
  documentStructureId: string;
}

export interface IDeleteStatusModalDocument {
  projectId?: string;
  body: {
    documentStructureId: string;
  };
}

export interface IUpdateWipStatus {
  projectId?: string;
  body: {
    status: {
      wipStatusId?: string | number | boolean | undefined;
      comment: string;
    };
  };
}

export interface IWipDocuments {
  documentStructureId: string;
  clientType?: string;
  clientName?: string;
  purpose?: string;
  documentStructure: {
    title: string;
    documentStructureId: string;
    allowedTypes: string[];
  };
  documentURLs: IDocumentsUrL[];
}

export interface IGetSignedUrlBody {
  documentStructureId: string;
  documentSideTag: string;
  documentFileType: string;
}

export interface IGetSignedUrl {
  projectId?: string;
  body: IGetSignedUrlBody[];
}

export interface IMall {
  _id: string;
  name: string;
  cityId: string;
  address: string;
}

export interface IMallRequest {
  cityId: string;
}

export interface IAddMallRequest {
  name: string;
  cityId: string;
  address: string;
}

export type IWipResponse = GenericResponse<{ wip: IWip }>;
export type IShopPocResponse = GenericResponse<IShopPocDetails[]>;
export type IGetSignedUrlResponse = GenericResponse<{
  documentURLs: [
    {
      documentStructureId: string;
      uploadURL: IUploadUrl;
      documentSideTag: string;
      documentFileType: string;
    }
  ];
}>;
export type IWipStatusResponse = GenericResponse<{ statusList: IWipStatus[] }>;
export type IWipDocumentsResponse = GenericResponse<{
  wipDocuments: IWipDocuments[];
  approvedWIPDocuments: IWipDocuments[];
}>;
export type IGenericResponse = GenericResponse<void>;

export type IBMUsersResponse = GenericResponse<{
  brandManagerUsers: IBMUser[];
}>;

export type IMallResponse = GenericResponse<{ malls: IMall[] }>;

export type ILLListReponse = GenericResponse<{ landlords: INewLandLord[]; paginationParams: IPaginationParam }>;

export type ICreateLLReponse = GenericResponse<{ landlord: INewLandLord }>;

// Injecting auth endpoints to base API
export const wipApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createWip: builder.mutation<IWipResponse, ICreateWipBody>({
      query: (body) => ({
        url: WIP_URLS.WIP,
        method: "POST",
        body,
      }),
      invalidatesTags: ["RequirementDetails"],
    }),
    createIndependentWip: builder.mutation<IWipResponse, IIndependentWIPBody>({
      query: (body) => ({
        url: WIP_URLS.WIP + WIP_URLS.INDEPENDENT,
        method: "POST",
        body,
      }),
      invalidatesTags: ["RequirementDetails"],
    }),
    updateWipDetails: builder.mutation<IWipResponse, IWipBody>({
      query: (body) => ({
        url: `${WIP_URLS.WIP_PROJECT_1}${body?.projectId}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (data) => {
        if (data?.success) {
          return ["WipDetails", "WipList"];
        }
        return [];
      },
    }),
    deleteDraftWIP: builder.mutation<IWipResponse, string>({
      query: (projectId) => ({
        url: `${WIP_URLS.WIP_PROJECT_1}${projectId}${WIP_URLS.DRAFT}`,
        method: "DELETE",
      }),
      invalidatesTags: (data) => {
        if (data?.success) {
          return ["WipDetails", "WipList"];
        }
        return [];
      },
    }),
    getShopPoc: builder.query<IShopPocResponse, string>({
      query: (shopId) => ({
        url: `/shop/${shopId}/poc`,
        method: "GET",
      }),
    }),
    getWipSignedUrl: builder.mutation<IGetSignedUrlResponse, IGetSignedUrl>({
      query: ({ projectId, body }) => ({
        url: `${WIP_URLS.WIP_PROJECT_1}${projectId}/documents/upload-url`,
        method: "POST",
        body,
      }),
    }),
    uploadDocumentToUrl: builder.mutation<void, any>({
      query: ({ url, body }) => ({
        url,
        method: "POST",
        body,
        credentials: "omit",
      }),
      transformResponse: (_, meta: any) => {
        const res = meta?.response?.headers.get("location");
        return res;
      },
    }),
    updateDocumentToUrl: builder.mutation<void, IDocumentBody>({
      query: ({ projectId, body }) => ({
        url: `${WIP_URLS.WIP_PROJECT_1}${projectId}/documents`,
        method: "POST",
        body,
      }),
    }),
    getWipDocuments: builder.query<IWipDocumentsResponse, string>({
      query: (projectId) => ({
        url: `${WIP_URLS.WIP_PROJECT_1}${projectId}/documents`,
        method: "GET",
      }),
    }),
    deleteDocument: builder.mutation<IGenericResponse, IDeleteDocumentBody>({
      query: ({ projectId, body }) => ({
        url: `${WIP_URLS.WIP_PROJECT_1}${projectId}/status/documents`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["WipDetails"],
    }),
    getWipDynamicStatus: builder.query<IWipStatusResponse, IGetWipDynamicStatusBody>({
      query: ({ projectId, documentStructureId }) => ({
        url: `${WIP_URLS.WIP_PROJECT_1}${projectId}/status/documents/${documentStructureId}`,
        method: "GET",
      }),
    }),
    getWipStatusList: builder.query<IWipStatusResponse, string>({
      query: (projectId) => ({
        url: `${WIP_URLS.WIP_PROJECT_1}${projectId}/status/list`,
        method: "GET",
      }),
    }),
    deleteStatusModalDocument: builder.mutation<void, IDeleteStatusModalDocument>({
      query: ({ projectId, body }) => ({
        url: `${WIP_URLS.WIP_PROJECT_1}${projectId}/documents`,
        method: "DELETE",
        body,
      }),
    }),
    updateWipStatus: builder.mutation<IWipResponse, IUpdateWipStatus>({
      query: ({ projectId, body }) => ({
        url: `${WIP_URLS.WIP_PROJECT_1}${projectId}/status`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["WipDetails", "OptionList", "RequirementDetails", "WipList"],
    }),
    getBMUsers: builder.query<IBMUsersResponse, void>({
      query: () => ({
        url: `user/brand-manager-user/list`,
        method: "GET",
      }),
    }),
    freezeProjection: builder.mutation<GenericResponse<void>, boolean | undefined>({
      query: (isConsulting) => ({
        url: isConsulting ? `/wip/consulting/freeze` : `/wip/freeze`,
        method: "POST",
      }),
      invalidatesTags: ["wipProjection"],
    }),
    getCurrentMonthFreezeStatus: builder.query<GenericResponse<{ isFreezed: boolean }>, boolean | undefined>({
      query: (isConsulting) => ({
        url: isConsulting ? `/wip/consulting/freeze-status` : `/wip/freeze-status`,
        method: "GET",
      }),
      providesTags: ["wipProjection"],
    }),

    getInvoiceTable: builder.query<GenericResponse<IAnalyticsTable>, string>({
      query: (projectId) => ({
        url: `${WIP_URLS.WIP}${WIP_URLS.PROJECT}/${projectId}${WIP_URLS.INVOICES}`,
        method: "GET",
      }),
      providesTags: ["wipProjection"],
    }),
    wipCommentList: builder.query<GenericResponse<{ comments: IComment[] }>, string | null | undefined>({
      query: (projectId) => ({
        url: `${WIP_URLS.WIP_PROJECT}${projectId}/${WIP_URLS.COMMENTS}`,
        method: "GET",
      }),
      providesTags: ["wipComment"],
    }),
    postCommentToWip: builder.mutation<GenericResponse<void>, { projectId: string | null | undefined; text: string }>({
      query: ({ projectId, text }) => ({
        url: `${WIP_URLS.WIP_PROJECT}${projectId}/${WIP_URLS.COMMENT}`,
        method: "POST",
        body: { text: text },
      }),
      invalidatesTags: ["wipComment", "WipList"],
    }),
    getMallList: builder.query<IMallResponse, IMallRequest>({
      query: ({ cityId }) => ({
        url: `${WIP_URLS.GET_MALL_LIST}/${cityId}`,
      }),
      providesTags: ["Malls"],
    }),
    addMall: builder.mutation<GenericResponse, IAddMallRequest>({
      query: (body) => ({
        url: WIP_URLS.MALL,
        body,
        method: "POST",
      }),
      invalidatesTags: ["Malls"],
    }),
    getWipByProjectId: builder.query<IWipResponse, string>({
      query: (porjectId) => ({
        url: `${WIP_URLS.WIP_PROJECT}${porjectId}`,
        method: "GET",
      }),
      providesTags: ["WipDetails"],
    }),
    getInvoicesTableForWIP: builder.query<any, string>({
      query: (projectId) => ({
        url: `/wip/project/${projectId}/invoice/request`,
        method: "GET",
      }),
      providesTags: ["WipDetails", "IRFTableWip"],
    }),

    createLandLord: builder.mutation<ICreateLLReponse, INewLandLord>({
      query: (body) => ({
        url: WIP_URLS.LL,
        method: "POST",
        body,
      }),
      invalidatesTags: ["LandLordList"],
    }),
    updateLandLord: builder.mutation<ICreateLLReponse, INewLandLord>({
      query: (body) => ({
        url: `${WIP_URLS.LL}/${body?._id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["LandLordList"],
    }),
    getLLSearchSuggestions: builder.mutation<ILLListReponse, string>({
      query: (query) => ({
        url: "/landlord/search/auto-suggest",
        method: "POST",
        body: {
          query,
          limit: 7,
        },
      }),
    }),
    getLLList: builder.query<ILLListReponse, paginationParams>({
      query: (params) => ({
        url: "/landlord/list",
        method: "GET",
        params,
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        const isPageOne = newItems.data?.paginationParams?.previous === null;
        if (newItems.success && currentCache?.data?.landlords && newItems.data?.landlords?.length && !isPageOne) {
          currentCache.data?.landlords.push(...(newItems.data?.landlords || []));
          currentCache.data.paginationParams = newItems.data.paginationParams;
          return currentCache;
        } else {
          return newItems;
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: ["LandLordList"],
    }),
  }),
});

export const {
  useCreateWipMutation,
  useCreateIndependentWipMutation,
  useGetWipSignedUrlMutation,
  useGetShopPocQuery,
  useUploadDocumentToUrlMutation,
  useUpdateDocumentToUrlMutation,
  useLazyGetWipDocumentsQuery,
  useDeleteDocumentMutation,
  useLazyGetWipDynamicStatusQuery,
  useUpdateWipDetailsMutation,
  useLazyGetWipStatusListQuery,
  useDeleteStatusModalDocumentMutation,
  useUpdateWipStatusMutation,
  useGetBMUsersQuery,
  useFreezeProjectionMutation,
  useGetCurrentMonthFreezeStatusQuery,
  useGetInvoiceTableQuery,
  useWipCommentListQuery,
  usePostCommentToWipMutation,
  useGetMallListQuery,
  useAddMallMutation,
  useGetWipByProjectIdQuery,
  useLazyGetWipByProjectIdQuery,
  useGetInvoicesTableForWIPQuery,
  useGetLLSearchSuggestionsMutation,
  useCreateLandLordMutation,
  useUpdateLandLordMutation,
  useDeleteDraftWIPMutation,
  useGetLLListQuery,
} = wipApi;
