import { LISTING_URLS } from "constants/ApiUrls";
import { FILTER_TYPES, OPTION_LIST_SHOP_STATUS } from "constants/ListingEnums";

import { api, GenericResponse, paginationParams } from "./api";
import { IBrand, ICarpetArea } from "./createRequirmentApi";
import { IPaginationParam } from "./notificationsApi";

export interface ITreeChildren {
  key: string;
  title: string;
  children: ITreeChildren[];
}
export interface IFilterData {
  id: string;
  title: string;
  children?: ITreeChildren[];
}

export type valueType = string | string[] | ICarpetArea;

export interface SelectedFiltersObject {
  [id: string]: valueType;
}

export interface IFiltersList {
  [id: string]: valueType;
}

export interface ISelectedFilters {
  selectedFilters: IFiltersList;
}

export interface IGetFilteredRequirementsRequest extends ISelectedFilters {
  params: paginationParams;
}

export interface IFilter {
  id: string;
  title: string;
  type: FILTER_TYPES;
  defaultValue: valueType;
  selected: valueType;
  data: IFilterData[];
  hasAll?: boolean;
  showSearch?: boolean;
}

export interface ILocality {
  _id: string;
  name: string;
}
export type ICity = ILocality;
export interface IMatchingShops {
  Available: string[];
  Expired: string[];
  New: string[];
  Updated: string[];
}

export type TotalReqCountResponse = GenericResponse<{
  requirementDetailsCount: number;
}>;

export interface IRequirement {
  _id: string;
  locality: ILocality;
  floors: string[];
  shopType: string;
  status: OPTION_LIST_SHOP_STATUS;
  brandRequirementId: string;
}

export interface IBrandRequirement {
  _id: string;
  brandPOCId: string;
  carpetArea: ICarpetArea;
  minFloorPlate: number;
  height: number;
  requirementDetails: IRequirement[];
  frontage: number;
  remarks: string;
  brand: IBrand;
}

export interface IGetFilteredListBody extends ISelectedFilters {
  params: paginationParams;
}

export interface PaginationParams {
  count: number;
  next: number;
  previous: number;
}

export type IFilterListResponse = GenericResponse<{
  filters: IFilter[];
  moreFilters?: IFilter[];
}>;
export type IGetRequirementsResponse = GenericResponse<{
  brandRequirements: IBrandRequirement[];
  paginationParams: IPaginationParam;
  requirementDetailsCount: number;
}>;

export const filtersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFilters: build.query<IFilterListResponse, ISelectedFilters>({
      query: (body) => ({
        url: LISTING_URLS.GET_FILTERS,
        body: body,
        method: "POST",
      }),
    }),
    getRequirementList: build.query<IGetRequirementsResponse, IGetFilteredRequirementsRequest>({
      query: ({ selectedFilters, params }) => ({
        url: LISTING_URLS.GET_REQUIREMENT_LIST_V2,
        body: { selectedFilters },
        method: "POST",
        params,
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        const { selectedFilters } = queryArgs;
        return { selectedFilters };
      },
      merge: (currentCache, newItems) => {
        const isPageOne = newItems.data?.paginationParams?.previous === null;
        if (newItems.success && currentCache?.data?.brandRequirements && newItems.data && !isPageOne) {
          currentCache.data?.brandRequirements.push(...(newItems.data?.brandRequirements || []));
          currentCache.data.paginationParams = newItems.data.paginationParams;
        } else {
          return newItems;
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getTotalRequirementCounts: build.query<TotalReqCountResponse, void>({
      query: () => ({
        url: LISTING_URLS.GET_TOTAL_REQ_COUNT,
        method: "GET",
      }),
      providesTags: ["RequirementDetailsCount"],
    }),
  }),
});

export const { useGetFiltersQuery, useGetRequirementListQuery, useGetTotalRequirementCountsQuery } = filtersApi;
