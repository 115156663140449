import { ArBreadCrumb, ArImage } from "@anarock/ar-common-react";
import { ArBreadCrumbItem } from "@anarock/ar-common-react/dist/ui-components/ar-breadcrumb";
import { FormInstance } from "antd";
import { IFormError } from "app/services/api";
import { IBrand, IFloor, ILocation, IRequirement } from "app/services/createRequirmentApi";
import { FORM_FIELDS } from "constants/RequirementFrom";
import { APP_ROUTES } from "constants/Routes";
import { STRING_CONSTANTS } from "constants/StringConstants";
import React from "react";
import { useNavigate } from "react-router-dom";

import BrandLogoPlaceholder from "../../../assets/images/brandLogoPlaceholder.png";
import styles from "../index.module.scss";

export const option = {
  label: "test",
  value: "test",
};

export const RequirementBreadCrumb: React.FC<{ path: string }> = ({ path }) => {
  const navigate = useNavigate();
  const handleClick = (path: string) => navigate(path);
  return (
    <ArBreadCrumb className={styles.breadCrumb}>
      <ArBreadCrumbItem
        className={styles.breadCrumbItem}
        key={STRING_CONSTANTS.HOME}
        onClick={() => handleClick(APP_ROUTES.REQUIREMENT)}
      >
        {STRING_CONSTANTS.HOME}
      </ArBreadCrumbItem>
      <ArBreadCrumbItem>{path}</ArBreadCrumbItem>
    </ArBreadCrumb>
  );
};

export const getCityId = (city: string, cityList: ILocation[]) => {
  const cityObj = cityList?.find((item) => item.name === city);
  return cityObj ? cityObj._id : undefined;
};
export const getCityName = (cityId: string, cityList: ILocation[]) => {
  const cityObj = cityList?.find((item) => item._id === cityId);
  return cityObj ? cityObj.name : undefined;
};

export const getBrandId = (brand: string, brandList: IBrand[]) => {
  const brandObj = brandList?.find((item) => item.brandName === brand);
  return brandObj;
};

// for floor and shop type
export const getModalOptions = (itemList: IFloor[]) => {
  const list = itemList?.map((item) => ({
    label: item,
    value: item,
  }));
  return list;
};
// for city and locality
export const getCityModalOptions = (itemList: ILocation[]) => {
  const list = itemList?.map((item) => ({
    label: item.name,
    value: item.name,
  }));
  return list;
};

export const getLocalityModalOptions = (itemList: ILocation[]) => {
  const list = itemList?.map((item) => ({
    label: item.name,
    value: item.name,
  }));
  return list;
};

export const getProfitCenterModalOptions = (itemList: string[]) => {
  const list = itemList?.map((item) => ({
    label: item,
    value: item,
  }));
  return list;
};

// function to get brand options
export const getBrandLabel = (brand: IBrand, locality?: string) => {
  return (
    <div className={styles.brandLogo}>
      <BrandPrefix alt={brand?.brandName} brandUrl={brand.brandLogoUrl} imageClassName={styles.brandImage} />
      <div>
        <p className={styles.brandLabel}>{brand.brandName}</p>
        {locality && <p className={"text-regular"}>{locality}</p>}
      </div>
    </div>
  );
};

export const getBrandOptions = (brandList: IBrand[]) => {
  return brandList.map((item) => ({
    label: getBrandLabel(item),
    value: item.brandName,
  }));
};

export interface FieldData {
  name: string;
  errors: string[];
}

export const setFormState = (formErrors: IFormError[], form: FormInstance) => {
  const errors: FieldData[] = [];
  formErrors.forEach((item) => {
    switch (item.param) {
      case "carpetArea":
        errors.push({
          name: FORM_FIELDS.MAX_CARPET_AREA,
          errors: [item.reason],
        });
        break;
      default: {
        errors.push({
          name: item.param,
          errors: [item.reason],
        });
      }
    }
  });
  form.setFields(errors);
};

export const BrandLogoPlaceHolder = () => {
  return <img loading="lazy" width={14} height={16} src={BrandLogoPlaceholder} />;
};

export const BrandPrefix: React.FC<{
  brandUrl: string;
  imageClassName: string;
  alt: string;
}> = ({ brandUrl, imageClassName, alt }) => {
  return (
    <>
      <ArImage
        alt={alt}
        url={brandUrl}
        placeholder={<BrandLogoPlaceHolder />}
        imageClassName={imageClassName}
        placeholderClassName={styles.placeholderLogo}
      />
    </>
  );
};

export const getRequirements = (form: FormInstance, localityIds: string[]) => {
  const Localities = form.getFieldValue(FORM_FIELDS.HIGH_STREET);
  const requirementDetails: IRequirement[] = localityIds?.map((item, index) => {
    const req = {
      localityId: item,
      floors: form.getFieldValue(Localities[index]),
      shopType: form.getFieldValue(FORM_FIELDS.SHOP_TYPE),
    };
    return req;
  });
  return requirementDetails;
};
