import "./global.module.scss";

import { Spin } from "antd";
import { useAppSelector } from "app/hooks";
import { useCheckAuthQuery } from "app/services/auth";
import { useRegisterTokenMutation } from "app/services/notificationsApi";
import { isNullOrUndefiend } from "app/utils/helperFunctions";
import { getBillingRoutes, getUserRoleAccess } from "app/utils/userHelperFunctions";
import ArLayout from "components/appLayout";
import { AuthLayout } from "components/authLayout";
import Notify from "components/notifyUser";
import PushNotifications from "components/pushNotifications";
import { APP_ROUTES } from "constants/Routes";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { selectAuthUser, selectIsAuth, selectUserDefaultRoute } from "features/authentication/authSlice";
import ForgotPassword from "features/authentication/forgotPassword";
import ResetPassword from "features/authentication/resetPassword";
import ConsultingWIPBrokerageDetailsForm from "features/consultingWip/components/ConsultingWIP-BrokerageProjectionForm";
import ConsultingWIPCommercialDetailsForm from "features/consultingWip/components/ConsultingWIP-CommercialDetailsForm";
import ConsultingWIPContactDetailsForm from "features/consultingWip/components/ConsultingWIP-ContactDetailsForm";
import ConsultingWIPSummary from "features/consultingWip/components/ConsultingWIP-Summary";
import ConsultingWIPForm from "features/consultingWip/ConsultingWIPForm";
import { LocalitySegregatedShops, RequirementSegregatedShops } from "features/overView/partials";
import React, { Suspense, useCallback, useEffect, useMemo } from "react";
import ReactGA from "react-ga4";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";

import { deleteFcmToken, requestPermissionAndGetToken } from "../src/app/utils/firebase";

const InvoiceFeature = React.lazy(() => import("features/invoices"));

const KycInspectionView = React.lazy(() => import("features/kyc/components/KycInspectionView"));

const DeactivateRequirement = React.lazy(() => import("features/manageRequirement/deactivateRequirement"));
const EditRequirementForm = React.lazy(() => import("features/manageRequirement/editRequirement"));
const Properties = React.lazy(() => import("features/properties"));
const ManageShop = React.lazy(() => import("features/properties/manageShop"));
const MatchingProperties = React.lazy(() => import("features/requirementListingFlow/matchingProperties"));
const OptionsList = React.lazy(() => import("features/requirementListingFlow/optionsList"));
const Overview = React.lazy(() => import("features/overView"));
const DraftProperties = React.lazy(() => import("features/requirementListingFlow/draftProperties"));
const ArListing = React.lazy(() => import("features/requirementListingFlow/requiremnetListing"));
const Login = React.lazy(() => import("features/authentication/login"));
const RequirementForm = React.lazy(() => import("features/manageRequirement/create-requirement"));
const Marker = React.lazy(() => import("features/marker"));
const Brochure = React.lazy(() => import("features/brochure"));
const WIP = React.lazy(() => import("features/wip"));
const SurveyorAnalytics = React.lazy(() => import("features/analytics/surveyor"));
const SurvAnalyticsDetails = React.lazy(() => import("features/analytics/surveyor/detailsView"));

const AgentAnalytics = React.lazy(() => import("features/analytics/agent"));
const AgentAnalyticsDetails = React.lazy(() => import("features/analytics/agent/detailsView"));
const WIPAnalytics = React.lazy(() => import("features/analytics/wip/components/WIPAnalytics"));
const WIPAnalyticsDetails = React.lazy(() => import("features/analytics/wip/detailsView"));

const AnalyticsFeature = React.lazy(() => import("features/analytics"));

const WIPAnalyticsOutlet = React.lazy(() => import("features/analytics/wip"));

const PropertyListingOutlet = React.lazy(() => import("features/properties/shopListing/propertyListingOutlet"));

const PreviewWIPSheet = React.lazy(() => import("features/preview/previewWIPSheet"));

const CollectionsAnalyticsOutlet = React.lazy(() => import("features/analytics/collections"));
const CollectionsAnalyticsDetails = React.lazy(() => import("features/analytics/collections/analyticsDetails"));
const CollectionsAnalytics = React.lazy(() => import("features/analytics/collections/analyticsOverview"));
const Scorecard = React.lazy(() => import("features/analytics/wip/components/Scorecard"));

const CollectionsSheetPreview = React.lazy(() => import("features/preview/previewCollectionSheet"));
const BrokerageDetailsForm = React.lazy(() => import("features/wip/components/WIP-BrokerageProjectionForm"));
const CommercialDetailsForm = React.lazy(() => import("features/wip/components/WIP-CommercialDetailsForm"));
const ContactDetailsForm = React.lazy(() => import("features/wip/components/WIP-ContactDetailsForm"));
const WipDocuments = React.lazy(() => import("features/wip/components/WIP-Documents"));
const WIPSummary = React.lazy(() => import("features/wip/components/WIP-Summary"));

const InvoiceRequestForm = React.lazy(() => import("features/invoices/InvoiceRequestForm"));
const InvoicesSheetPreview = React.lazy(() => import("features/preview/previewInvoicesSheet"));
const IRFContactDetails = React.lazy(() => import("features/invoices/components/IRFContactDetails/index"));
const IRFBrokerageDetails = React.lazy(() => import("features/invoices/components/IRFBrokerageDetails/index"));
const IRFDocuments = React.lazy(() => import("features/invoices/components/IRFDocuments"));
const IRFSummary = React.lazy(() => import("features/invoices/components/IRFSummary"));

const WIPInvoicesTab = React.lazy(() => import("features/wip/components/WIP-Invoices-tab"));
const InspectionView = React.lazy(() => import("features/invoices/InspectionView"));

const PaymentClaim = React.lazy(() => import("features/paymentClaim"));
const ConsultingWipListing = React.lazy(() => import("features/consultingWip"));
const ConsultingWipDocuments = React.lazy(() => import("features/consultingWip/components/ConsultingWipDocuments"));

const ClaimPaymentInspectionView = React.lazy(
  () => import("features/paymentClaim/components/ClaimPaymentInspectionView")
);
const IndependentWIPOnboarding = React.lazy(() => import("features/wip/onboarding"));
const IntelData = React.lazy(() => import("features/intelData"));

const FeeLetterForm = React.lazy(() => import("features/feeLetter/feeLetterForm"));

const FeeLetterInspection = React.lazy(() => import("features/feeLetter"));

process.env.REACT_APP_ENV === "production" && ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID || "");

function App() {
  const isAuth = useAppSelector(selectIsAuth);
  const user = useAppSelector(selectAuthUser);
  const defaultRoute = useAppSelector(selectUserDefaultRoute);

  const {
    hasAccessToAgentAnalytics,
    hasAccessToMarker,
    hasAccessToProperties,
    hasAccessToRequirements,
    hasAccessToSurvAnalytics,
    hasAccessToWIP,
    hasAccessWIPAnalytics,
    hasAccessToInvoiceSheetPreview,
    hasAccessToRevenueSheetPreview,
    hasAccessToCollections,
    hasAccessToCollectionsAnanlytics,
    hasAccessToCollectionsPreview,
    hasAccessToInvoicesPreview,
    hasAccessToInvoices,
    hasAccessToPayments,
    hasAccessToIntelData,
  } = useMemo(() => getUserRoleAccess(user?.modules), [user]);

  const collectionListingRoute = useMemo(
    () => getBillingRoutes(APP_ROUTES.COLLECTIONS, user?.role?.name),
    [user?.role?.name]
  );
  const invoicesListingRoute = useMemo(
    () => getBillingRoutes(APP_ROUTES.INVOICES, user?.role?.name),
    [user?.role?.name]
  );
  const paymentsListingRoute = useMemo(
    () => getBillingRoutes(APP_ROUTES.PAYMENTS, user?.role?.name),
    [user?.role?.name]
  );

  const { isLoading, isFetching, data, isError } = useCheckAuthQuery(undefined, {
    skip: isNullOrUndefiend(isAuth),
  });

  const [registerToken] = useRegisterTokenMutation();

  const initiatePushNotifications = useCallback(async () => {
    try {
      const token = await requestPermissionAndGetToken();

      if (token) {
        try {
          await registerToken(token).unwrap();
        } catch (e) {
          console.error(e);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, [registerToken]);

  useEffect(() => {
    if (isLoading || isFetching) {
      return;
    }
    if (isAuth && !isNullOrUndefiend(user)) {
      // ReactGA.set({ user_id: user?.email });
    } else if (isError || data?.success === false) {
      // ReactGA.reset();
      deleteFcmToken();
    }
  }, [user, isAuth, isLoading, isFetching, data, isError]);

  useEffect(() => {
    if (isAuth) {
      initiatePushNotifications();
    }
  }, [isAuth, initiatePushNotifications]);

  return (
    <React.Fragment>
      <PushNotifications />
      <Brochure />
      {isLoading ? (
        <Spin tip={STRING_CONSTANTS.LOADING_PLEASE_WAIT} size="large" />
      ) : (
        <div className="App">
          <Suspense fallback={<Spin tip={STRING_CONSTANTS.LOADING_PLEASE_WAIT} size="large" />}>
            <Routes>
              <Route element={<UserRoutes isAllowed />}>
                <Route path={APP_ROUTES.DEFAULT} element={<Navigate to={defaultRoute} replace />} />
              </Route>
              {/* Access to marker */}
              <Route element={<UserRoutes isAllowed={hasAccessToMarker} />}>
                <Route path={APP_ROUTES.MARKER} element={<Marker />} />
              </Route>
              {/* Access to wip flow */}

              <Route element={<UserRoutes isAllowed={hasAccessToWIP} />}>
                <Route path={`${APP_ROUTES.WIP}`} element={<WIPAnalyticsOutlet />}>
                  <Route path={`${APP_ROUTES.LISTING}`} element={<WIPAnalyticsDetails />} />
                </Route>
                <Route
                  path={APP_ROUTES.WIP + APP_ROUTES.CREATE_WIP + "/:projectId"}
                  element={
                    <>
                      <WIP />
                    </>
                  }
                >
                  <Route path={APP_ROUTES.WIP_CONTACT_DETAILS} element={<ContactDetailsForm />} />
                  <Route path={APP_ROUTES.WIP_COMMERCIAL_DETAILS} element={<CommercialDetailsForm />} />
                  <Route path={APP_ROUTES.WIP_BROKERAGE_DETAILS} element={<BrokerageDetailsForm />} />
                  <Route path={APP_ROUTES.WIP_SUMMARY} element={<WIPSummary />} />
                  <Route path={APP_ROUTES.WIP_DOCUMENTS} element={<WipDocuments />}></Route>
                </Route>
                <Route
                  path={APP_ROUTES.WIP + APP_ROUTES.EDIT_WIP + "/:projectId"}
                  element={
                    <>
                      <WIP />
                    </>
                  }
                >
                  <Route path={APP_ROUTES.WIP_SUMMARY} element={<WIPSummary />} />
                  <Route path={APP_ROUTES.WIP_DOCUMENTS} element={<WipDocuments />}></Route>
                  <Route path={APP_ROUTES.WIP_INVOICES} element={<WIPInvoicesTab />}></Route>
                </Route>
                <Route
                  path={APP_ROUTES.WIP + APP_ROUTES.KYC + "/:kycId" + APP_ROUTES.INSPECTION}
                  element={<KycInspectionView />}
                ></Route>
                <Route
                  path={APP_ROUTES.WIP + APP_ROUTES.FEE_LETTER + APP_ROUTES.CREATE_WIP + "/:feeLetterId"}
                  element={<FeeLetterForm />}
                ></Route>
                <Route
                  path={APP_ROUTES.WIP + APP_ROUTES.FEE_LETTER + "/:feeLetterId"}
                  element={<FeeLetterInspection />}
                ></Route>

                <Route
                  path={`${APP_ROUTES.WIP}${APP_ROUTES.ONBOARDING}`}
                  element={<IndependentWIPOnboarding />}
                ></Route>

                {/* Create Consulting wip  */}

                <Route
                  path={APP_ROUTES.WIP + "/" + APP_ROUTES.CONSULTING + APP_ROUTES.CREATE_WIP + "/:projectId"}
                  element={
                    <>
                      <ConsultingWIPForm />
                    </>
                  }
                >
                  <Route path={APP_ROUTES.WIP_CONTACT_DETAILS} element={<ConsultingWIPContactDetailsForm />} />
                  <Route path={APP_ROUTES.WIP_COMMERCIAL_DETAILS} element={<ConsultingWIPCommercialDetailsForm />} />
                  <Route path={APP_ROUTES.WIP_BROKERAGE_DETAILS} element={<ConsultingWIPBrokerageDetailsForm />} />
                  <Route path={APP_ROUTES.WIP_SUMMARY} element={<ConsultingWIPSummary />} />
                  <Route path={APP_ROUTES.WIP_DOCUMENTS} element={<ConsultingWipDocuments />}></Route>
                </Route>

                <Route
                  path={APP_ROUTES.WIP + "/" + APP_ROUTES.CONSULTING + APP_ROUTES.EDIT_WIP + "/:projectId"}
                  element={
                    <>
                      <ConsultingWIPForm />
                    </>
                  }
                >
                  <Route path={APP_ROUTES.WIP_SUMMARY} element={<ConsultingWIPSummary />} />
                  <Route path={APP_ROUTES.WIP_DOCUMENTS} element={<ConsultingWipDocuments />}></Route>
                  <Route path={APP_ROUTES.WIP_INVOICES} element={<WIPInvoicesTab />}></Route>
                </Route>

                <Route path={APP_ROUTES.WIP + APP_ROUTES.INVOICES + "/:irfId"} element={<InvoiceRequestForm />}>
                  <Route path={APP_ROUTES.WIP_CONTACT_DETAILS} element={<IRFContactDetails />} />
                  <Route path={APP_ROUTES.WIP_BROKERAGE_DETAILS} element={<IRFBrokerageDetails />} />
                  <Route path={APP_ROUTES.WIP_DOCUMENTS} element={<IRFDocuments />}></Route>
                  <Route path={APP_ROUTES.WIP_SUMMARY} element={<IRFSummary />}></Route>
                </Route>
                {/* Consulting WIP route */}
                <Route
                  path={`${APP_ROUTES.WIP}/${APP_ROUTES.CONSULTING}/${APP_ROUTES.LISTING}`}
                  element={<ConsultingWipListing />}
                />
              </Route>
              {/* Access to collection flow */}
              <Route element={<UserRoutes isAllowed={hasAccessToCollections} />}>
                <Route
                  path={collectionListingRoute}
                  element={
                    <>
                      <CollectionsAnalyticsOutlet></CollectionsAnalyticsOutlet>
                      <CollectionsAnalyticsDetails />
                    </>
                  }
                ></Route>
              </Route>

              {/* Access to preview uploaded invoice sheet */}
              <Route element={<UserRoutes isAllowed={hasAccessToInvoiceSheetPreview} />}>
                <Route
                  path={`${APP_ROUTES.WIP}${APP_ROUTES.PREVIEW}${APP_ROUTES.INVOICE}/${APP_ROUTES.SHEET_ID}`}
                  element={<PreviewWIPSheet />}
                ></Route>
              </Route>

              {/* Access to preview uploaded revenue sheet */}
              <Route element={<UserRoutes isAllowed={hasAccessToRevenueSheetPreview} />}>
                <Route
                  path={`${APP_ROUTES.WIP}${APP_ROUTES.PREVIEW}${APP_ROUTES.REVENUE}/${APP_ROUTES.SHEET_ID}`}
                  element={<PreviewWIPSheet />}
                ></Route>
              </Route>

              {/* Access to preview uploaded collection sheet */}
              <Route element={<UserRoutes isAllowed={hasAccessToCollectionsPreview} />}>
                <Route
                  path={`${APP_ROUTES.COLLECTIONS}${APP_ROUTES.PREVIEW}/${APP_ROUTES.SHEET_ID}`}
                  element={<CollectionsSheetPreview />}
                ></Route>
              </Route>

              {/* Access to preview uploaded invoices sheet */}
              <Route element={<UserRoutes isAllowed={hasAccessToInvoicesPreview} />}>
                <Route
                  path={`${APP_ROUTES.INVOICES}${APP_ROUTES.PREVIEW}/${APP_ROUTES.SHEET_ID}`}
                  element={<InvoicesSheetPreview />}
                ></Route>
              </Route>

              {/* Access to WIP Analytics */}
              <Route element={<UserRoutes isAllowed={hasAccessWIPAnalytics} />}>
                <Route path={`${APP_ROUTES.ANALYTICS}/${APP_ROUTES.WIP_ANALYTICS}`} element={<WIPAnalyticsOutlet />}>
                  <Route path={""} element={<WIPAnalytics />}></Route>
                  <Route path={`${APP_ROUTES.ANALYTICS_DETAILS}`} element={<WIPAnalyticsDetails />} />
                  <Route path={`${APP_ROUTES.WIP_SCORECARD}`} element={<Scorecard />} />
                </Route>
              </Route>

              {/* Access to Surv Analytics */}
              <Route element={<UserRoutes isAllowed={hasAccessToSurvAnalytics} />}>
                <Route path={APP_ROUTES.ANALYTICS} element={<AnalyticsFeature />}>
                  <Route path={APP_ROUTES.SURVEYOR} element={<SurveyorAnalytics />}></Route>
                  <Route
                    path={`${APP_ROUTES.SURVEYOR}/${APP_ROUTES.ANALYTICS_DETAILS}`}
                    element={<SurvAnalyticsDetails />}
                  />
                </Route>
              </Route>

              {/* Access to agent Analytics */}
              <Route element={<UserRoutes isAllowed={hasAccessToAgentAnalytics} />}>
                <Route path={APP_ROUTES.ANALYTICS} element={<AnalyticsFeature />}>
                  <Route path={APP_ROUTES.AGENT} element={<AgentAnalytics />}></Route>
                  <Route
                    path={`${APP_ROUTES.AGENT}/${APP_ROUTES.ANALYTICS_DETAILS}`}
                    element={<AgentAnalyticsDetails />}
                  />
                </Route>
              </Route>

              {/* Access to Collection Analytics */}
              <Route element={<UserRoutes isAllowed={hasAccessToCollectionsAnanlytics} />}>
                <Route
                  path={`${APP_ROUTES.ANALYTICS}${APP_ROUTES.COLLECTIONS}`}
                  element={<CollectionsAnalyticsOutlet />}
                >
                  <Route path={""} element={<CollectionsAnalytics />}></Route>
                  <Route path={`${APP_ROUTES.ANALYTICS_DETAILS}`} element={<CollectionsAnalyticsDetails />} />
                </Route>
              </Route>

              {/* Access to Requiremnet flow */}
              <Route element={<UserRoutes isAllowed={hasAccessToRequirements} />}>
                <Route
                  path={APP_ROUTES.REQUIREMENT + "/" + APP_ROUTES.REQ_DETAILS}
                  element={<DeactivateRequirement />}
                />
                <Route
                  path={APP_ROUTES.REQUIREMENT + "/" + APP_ROUTES.EDIT_REQUIREMENT}
                  element={<EditRequirementForm />}
                />
                <Route path={APP_ROUTES.REQUIREMENT} element={<ArListing />}>
                  <Route path={APP_ROUTES.MATCHING_PROPERTIES} element={<MatchingProperties />} />
                  <Route path={APP_ROUTES.OPTION_LIST} element={<OptionsList />} />
                  <Route path={APP_ROUTES.DRAFT_PROPERTIES} element={<DraftProperties />} />
                </Route>
                <Route path={APP_ROUTES.CREATE_REQUIREMENT} element={<RequirementForm />} />
              </Route>

              {/* Access to properties tab */}
              <Route element={<UserRoutes isAllowed={hasAccessToProperties} />}>
                <Route path={APP_ROUTES.PROPERTIES} element={<Properties />}>
                  <Route path={APP_ROUTES.PROPERTIES_NEW} element={<PropertyListingOutlet new={true} />} />
                  <Route path={APP_ROUTES.PROPERTIES_PREVIOUS} element={<PropertyListingOutlet new={false} />} />
                  <Route path={APP_ROUTES.SHOP_INFO} element={<ManageShop />} />
                </Route>
                <Route path={APP_ROUTES.OVERVIEW} element={<Overview />}>
                  <Route path={APP_ROUTES.OVERVIEW_REQUIREMENTS} element={<RequirementSegregatedShops />} />
                  <Route path={APP_ROUTES.LOCALITY} element={<LocalitySegregatedShops />} />
                </Route>
              </Route>

              {/* Access to invoices */}
              <Route element={<UserRoutes isAllowed={hasAccessToInvoices} />}>
                <Route path={invoicesListingRoute} element={<InvoiceFeature />} />
                <Route
                  path={APP_ROUTES.INVOICES + "/:irfId"}
                  element={
                    <>
                      <InvoiceRequestForm />
                    </>
                  }
                >
                  <Route path={APP_ROUTES.WIP_SUMMARY} element={<IRFSummary />}></Route>
                </Route>
                <Route path={APP_ROUTES.INVOICES + "/:irfId" + APP_ROUTES.INSPECTION} element={<InspectionView />} />
              </Route>

              {/* Access to Payments Module */}
              <Route element={<UserRoutes isAllowed={hasAccessToPayments} />}>
                <Route path={paymentsListingRoute} element={<PaymentClaim />} />

                <Route
                  path={APP_ROUTES.PAYMENTS + "/:paymentId" + APP_ROUTES.INSPECTION}
                  element={<ClaimPaymentInspectionView />}
                />
              </Route>

              {/* Access to Market Intel Data Module */}
              <Route element={<UserRoutes isAllowed={hasAccessToIntelData} />}>
                <Route path={APP_ROUTES.INTEL_DATA} element={<IntelData />}></Route>
              </Route>

              {/* User flow in case user is logged out */}
              <Route element={<AuthRoutes />}>
                <Route
                  path={APP_ROUTES.LOGIN}
                  element={
                    <AuthLayout>
                      <Login />
                    </AuthLayout>
                  }
                />
              </Route>

              {/* These pages are independent of user Auth */}
              <Route
                path={APP_ROUTES.FORGOT_PASSWORD}
                element={
                  <AuthLayout>
                    <ForgotPassword />
                  </AuthLayout>
                }
              />
              <Route
                path={APP_ROUTES.RESET_PASSWORD}
                element={
                  <AuthLayout>
                    <ResetPassword />
                  </AuthLayout>
                }
              />
              <Route path={APP_ROUTES.NOTIFY} element={<Notify />} />
            </Routes>
          </Suspense>
        </div>
      )}
    </React.Fragment>
  );
}

//login for Auth

const UserRoutes = ({ isAllowed }: { isAllowed?: boolean }) => {
  const location = useLocation();
  const loggedIn = useAppSelector(selectIsAuth);
  return loggedIn ? (
    <ArLayout>
      {isAllowed ? <Outlet /> : <div className="accessError">{STRING_CONSTANTS.ACCESS_ERROR_MESSAGE}</div>}
    </ArLayout>
  ) : (
    <Navigate to={APP_ROUTES.LOGIN} replace state={{ from: location }} />
  );
};

const AuthRoutes = () => {
  const location = useLocation();
  const from = location.state?.from;
  const loggedIn = useAppSelector(selectIsAuth);
  const defaultRoute = useAppSelector(selectUserDefaultRoute);

  return loggedIn ? <Navigate to={from ? from : defaultRoute} replace></Navigate> : <Outlet />;
};

export default App;
