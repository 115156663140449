import { ArButton, ArModal, ArSearchBox, ButtonTypes } from "@anarock/ar-common-react";
import { ArRadioGroup } from "@anarock/ar-common-react/dist/ui-components/ar-radio";
import { Spin } from "antd";
import { INewLandLord } from "app/services/createRequirmentApi";
import { useGetLLListQuery, useGetLLSearchSuggestionsMutation } from "app/services/wip";
import { convertLLArrayToRadioOptions, convertLLAutoSuggestArrayToRadioOptions } from "app/utils/helperFunctions";
import { useGetInfiniteScrollParams } from "app/utils/infiniteScrollHook";
import { useToastService } from "components/toastService";
import { pageLimit } from "constants/pagination";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { MallListLLMallProps } from "./index.interface";
import AddLLModal from "./partials";

const LandLordListModal: React.FC<MallListLLMallProps> = ({ mallId, onSubmit, ...props }) => {
  const [addModalOpen, setAddModal] = useState(false);
  const [open, setOpen] = useState(props.open || false);
  const [value, setValue] = useState("");
  const [searchText, setSearchText] = useState("");
  const { pageNo, onScroll } = useGetInfiniteScrollParams();

  const { errorToast } = useToastService();

  const [fetchSuggestedLLs, { data, isLoading }] = useGetLLSearchSuggestionsMutation();
  const { data: llList, isFetching: listFetching } = useGetLLListQuery({ pageNo, pageLimit }, { skip: !open });

  const fetchLLList = useCallback(
    async (text: string) => {
      if (text.length < 3) {
        return;
      }
      try {
        await fetchSuggestedLLs(text).unwrap();
      } catch {
        errorToast(STRING_CONSTANTS.SOMETHING_WENT_WRONG);
      }
    },
    [errorToast, fetchSuggestedLLs]
  );

  const debouncedGetResults = useMemo(() => debounce(fetchLLList, 300), [fetchLLList]);

  useEffect(() => {
    if (searchText && open) {
      debouncedGetResults(searchText);
    }
  }, [searchText, open, debouncedGetResults]);

  useEffect(() => {
    setOpen(props.open || false);
    setAddModal(false);
    setSearchText("");
  }, [props.open]);

  const toggelAddLLModal = () => {
    setAddModal(!addModalOpen);
    setOpen(!open);
  };

  const handleModalSubmit = () => {
    const obj =
      data?.data?.landlords?.find(({ _id }) => _id === value) ||
      llList?.data?.landlords?.find(({ _id }) => _id === value);
    if (obj) {
      props?.sendDataAndCloseModal?.(obj);
    }
  };

  const closeBothModals = (ll: INewLandLord) => {
    setAddModal(false);
    props?.sendDataAndCloseModal?.(ll);
  };
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

    if (scrollHeight - scrollTop === clientHeight) {
      onScroll(e);
    }
  };

  const showEmptyBtn =
    (searchText?.length > 3 && !isLoading && data?.data?.landlords?.length === 0) ||
    llList?.data?.landlords?.length === 0;

  const addMoreBtnprops = showEmptyBtn ? { onClick: toggelAddLLModal, children: STRING_CONSTANTS.ADD_N_LL } : undefined;

  return (
    <React.Fragment>
      <ArModal
        title="Choose LandLord"
        width={500}
        destroyOnClose
        submitText={STRING_CONSTANTS.DONE}
        footer={
          !showEmptyBtn
            ? [
                <ArButton key="1" type={ButtonTypes.Secondary} size="large" onClick={toggelAddLLModal}>
                  {STRING_CONSTANTS.ADD_N_LL}
                </ArButton>,
                <ArButton key="1" size="large" onClick={handleModalSubmit}>
                  {STRING_CONSTANTS.DONE}
                </ArButton>,
              ]
            : null
        }
        open={open}
        {...props}
      >
        <ArSearchBox value={searchText} onChange={(e) => setSearchText(e.target?.value)} allowClear />

        {searchText ? (
          <div style={{ height: 300, marginTop: 16, overflowY: "scroll" }}>
            {isLoading ? (
              <Spin />
            ) : (
              <ArRadioGroup
                options={searchText?.length < 3 ? [] : convertLLAutoSuggestArrayToRadioOptions(data?.data?.landlords)}
                onChange={(val) => setValue(String(val))}
                emptyText={searchText?.length ? "No Results Found" : "Search to see Results"}
                addDataButtonProps={addMoreBtnprops}
              ></ArRadioGroup>
            )}
          </div>
        ) : (
          <div style={{ height: 300, marginTop: 16, overflowY: "scroll" }} onScroll={handleScroll}>
            <ArRadioGroup
              options={convertLLArrayToRadioOptions(llList?.data?.landlords || [])}
              onChange={(val) => setValue(String(val))}
              emptyText={searchText?.length ? "No Results Found" : "Search to see Results"}
              addDataButtonProps={addMoreBtnprops}
            ></ArRadioGroup>
            {listFetching ? <Spin /> : null}
          </div>
        )}
      </ArModal>
      <AddLLModal
        open={addModalOpen}
        onCancel={toggelAddLLModal}
        closeModal={toggelAddLLModal}
        sendDataAndCloseModal={closeBothModals}
        mallId={mallId}
      />
    </React.Fragment>
  );
};

export default LandLordListModal;
