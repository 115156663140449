const REQUIRED_FIELD = "This field is required";
const INVALID_NUMBER = "Please enter a valid whole number";
const INVALID_EMAIL = "Please enter a email";
const INVALID_PHONE_NUMBER = "Please enter a 10 digit mobile number";
const INVALID_PINCODE = "Please enter a valid pincode";

const INVALID_GST = "Please enter a valid GST Number";
const INVALID_PAN = "Please enter a valid Pan Number";
const INVALID_AADHAR = "Please enter a valid Aadhar Number";

const FEE_AMT_CANT_BE_MORE_THAN_BROKERAGE = "Fee can not be more than Brokerage";
const LESS_THAN_OUTSTANDING = "Amount+TDS must be less than outstanding";
const SUM_EQUAL_TOTAL = "Sum must be equal to Total.";
const SUM_OF_COMMISSION = "Sum of Commission should not be greater than 100%.";
const SUM_OF_FEE_SHARE = "Sum of fee share should be 100%.";
const TOTAL_ALL_100 = "Total allocation needs to be 100%";
const TOTAL_ALL_CANT_BE_MORE_100 = "Total allocation needs to be 100% or less.";
const LESS_THAN_100 = "Value should be less than or equal to 100.";

export default Object.assign({
  REQUIRED_FIELD,
  INVALID_NUMBER,
  INVALID_EMAIL,
  INVALID_PHONE_NUMBER,
  INVALID_PINCODE,
  INVALID_GST,
  FEE_AMT_CANT_BE_MORE_THAN_BROKERAGE,
  LESS_THAN_OUTSTANDING,
  SUM_EQUAL_TOTAL,
  INVALID_AADHAR,
  SUM_OF_COMMISSION,
  INVALID_PAN,
  SUM_OF_FEE_SHARE,
  TOTAL_ALL_100,
  TOTAL_ALL_CANT_BE_MORE_100,
  LESS_THAN_100,
});
